import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'HHMP geofencing';
 }



/* @Component({
  selector: 'app-root',
  template: `
  <my-login-form (submitEM)="login($event)">Your Form</my-login-form>
  <!-- <my-login-form [error]="'Username or password invalid'" >Your Form With Error Message</my-login-form> -->
  `,
  styles: []
})
export class AppComponent  {
  title = 'HHMP geofencing';
  constructor(private router: Router, private route: ActivatedRoute) { }
  login (ev: Event){
    console.log(ev);
    console.log(this.route);
    this.router.navigate(['geofencing'], { relativeTo: this.route }).then(x => {
      console.log("ciao");
      return true;     
    })  
 }
} */

