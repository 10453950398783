import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { User } from "src/app/services/geofencing.service";
import { DialogData } from '../geofencing/geofencing.component';


/**
 * @title Dialog with header, scrollable content and actions
 */


@Component({
  selector: 'users-dialog',
  templateUrl: 'users.component.html',
  styleUrls: ["./users.component.css"],
})
export class UsersComponent {

  usersData: User[]; 
  selectedUsersData: User[];
  usersSelected: User[];
  usersFinalSelected: User[];
  
  
  constructor (
    public dialogRef: MatDialogRef<UsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {   
    this.usersData = this.data.users;
    this.selectedUsersData = this.data.users_selected; 
    this.usersSelected = [];
    this.usersFinalSelected = [];         
  }
  
  onNoClick(): void {    
    this.dialogRef.close();
  }


  ngOnInit(): void{

/*     for (let i=0; i < this.usersData.length; i++) {
      this.usersData[i].selected = false;
    } */
        
  }

  pushSelected() {    
    this.selectedUsersData = [...this.usersSelected];    
  }

  pushAll() {    
    this.selectedUsersData = [...this.usersData];   
  }

  popSelected() {
    this.usersFinalSelected.forEach(su => {      
      this.selectedUsersData = this.selectedUsersData.filter(x => x !== su);
    });    
  }

  popAll() {
    this.selectedUsersData = [];
  }

  onCloseClick() {         
    this.dialogRef.close(this.selectedUsersData);
  }

}
