<mat-card>
    <mat-card-title>Geofencing - pilot selection</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="submit($event.submitter.id)">        
        <p></p>
        <p>      
            <mat-form-field>
            <!-- <mat-label>Select pilot</mat-label> -->
            <mat-select formControlName="pilot_nr">                                  
                <mat-option *ngFor="let p of pilotTable" [value]="p.pilot"
                    (onSelectionChange)="onPilotChange(p, $event)">              
                {{p.name}}
                </mat-option>                
            </mat-select>
            </mat-form-field>   
        </p>

        <p *ngIf="error" class="error">
            {{ error }}
        </p>

        <div class="row">
            <div class="col-12">
                <div class="button">
                    <button type="submit" id="Geo" mat-button [disabled]="form.invalid">Go to auth</button>
                </div>
            </div>            
        </div>

        </form>
    </mat-card-content>
</mat-card>