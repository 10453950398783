import { Input, Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { GeofencingService } from 'src/app/services/geofencing.service';

@Component({
  selector: 'app-login',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent {
  loading: boolean = false;
  submitted: boolean = false;

  pilot: string = '';
  btnName: string = '';
  pilotTable: any;

  constructor(private geofencingService: GeofencingService) {
    this.pilotTable = this.geofencingService.getPilotTable();
  }

  form: FormGroup = new FormGroup({
    pilot_nr: new FormControl('', [Validators.required]),
  });

  setBtnName(name: string) {
    this.btnName = name;
  }

  validateLogin(appl: string) {
    let outcome: any = {
      valid: false,
      pilot: '',
      api_url: '',
      re_path: '',
    };
    return outcome;
  }

  private rndState(): string {
    return Math.round(Math.random() * 100000000).toString();
  }

  submit(event: string) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    } else {
      for (let p of this.pilotTable) {
        if (this.pilot == p.pilot) {
          switch (this.pilot) {
            case 'pilot100test':
              window.location.href =
                'https://tcarepath.klinikumbielefeld.de:10443/onauth/api/carepath/authorize?response_type=id_token%20token&client_id=geofencing&scope=profile%20openid%20email%20roles%20offline_access%20user%2F*.*%20patient%2F*.*&prompt=login&redirect_uri=https://ls.carepath.care/pilot100test&aud=fhir-repo&state=' +
                this.rndState();
              break;
            case 'pilot100prod':
              window.location.href =
                'https://carepath.klinikumbielefeld.de:10443/onauth/api/carepath/authorize?response_type=id_token%20token&client_id=geofencing&scope=profile%20openid%20email%20roles%20offline_access%20user%2F*.*%20patient%2F*.*&prompt=login&redirect_uri=https://ls.carepath.care/pilot100prod&aud=fhir-repo&state=' +
                this.rndState();
              break;
            case 'pilot200':
              window.location.href =
                'https://pilot200.carepath.care/onauth/api/carepath/authorize?response_type=id_token%20token&client_id=geofencing&scope=profile%20openid%20email%20roles%20offline_access%20user%2F*.*%20patient%2F*.*&prompt=login&redirect_uri=https://ls.carepath.care/pilot200&aud=fhir-repo&state=' +
                this.rndState();
              break;
            case 'pilot300':
              window.location.href =
                'https://pilot300.carepath.care/onauth/api/carepath/authorize?response_type=id_token%20token&client_id=geofencing&scope=profile%20openid%20email%20roles%20offline_access%20user%2F*.*%20patient%2F*.*&prompt=login&redirect_uri=https://ls.carepath.care/pilot300&aud=fhir-repo&state=' +
                this.rndState();
              break;
            case 'pilot400':
              window.location.href =
                'https://pilot400.carepath.care/onauth/api/carepath/authorize?response_type=id_token%20token&client_id=geofencing&scope=profile%20openid%20email%20roles%20offline_access%20user%2F*.*%20patient%2F*.*&prompt=login&redirect_uri=https://ls.carepath.care/pilot400&aud=fhir-repo&state=' +
                this.rndState();
              break;
            case 'pilot500':
              window.location.href =
                'https://pilot500.carepath.care/onauth/api/carepath/authorize?response_type=id_token%20token&client_id=geofencing&scope=profile%20openid%20email%20roles%20offline_access%20user%2F*.*%20patient%2F*.*&prompt=login&redirect_uri=https://ls.carepath.care/pilot500&aud=fhir-repo&state=' +
                this.rndState();
              break;
            case 'home':
              window.location.href =
                'https://kroniq.srdc.com.tr/onauth/api/carepath/authorize?response_type=id_token%20token&client_id=aicp&scope=profile%20openid%20email%20roles%20offline_access%20user%2F*.*%20patient%2F*.*&prompt=login&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fhome&aud=fhir-repo&state=' +
                this.rndState();
              break;
          }
          return true;
        }
      }
      return false;
    }
  }

  onPilotChange(p: any, event: any) {
    if (event.isUserInput) {
      this.pilot = p.pilot;
    }
  }

  @Input() error: string | null | undefined;

  @Output() submitEM = new EventEmitter();
}
