import './polyfills';

import { NgModuleRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

type windowExtended = Window & typeof globalThis & {
  ngRef: NgModuleRef<AppModule>;
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
  // Ensure Angular destroys itself on hot reloads.    
    const _window = window as windowExtended;
    if (_window['ngRef']) {
      _window['ngRef'].destroy();
    }
    _window['ngRef'] = ref;    
}).catch(err => console.error(err));