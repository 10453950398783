<h3 mat-dialog-title>
<b>Save to other users</b></h3>
<mat-divider></mat-divider>    
<mat-grid-list cols="5" rowHeight="1:2.8">
  
  <mat-grid-tile colspan="2">    
    <div class="div-grid-tile">     
      <mat-selection-list [(ngModel)]="usersSelected">
        <mat-list-option *ngFor="let u of usersData" [value]="u">
          {{u.fhir_identifier}} &nbsp;&nbsp; {{u.name}}
        </mat-list-option>
      </mat-selection-list>
      </div>
  </mat-grid-tile>

  <mat-grid-tile colspan="1">    
    <div class="div-grid-tile2">      
        <div class="row">
          <div class="col-1">
            <button mat-button class="mat-usersbutton" (click)="pushSelected()"><b>></b></button>
          </div>
        </div>
        <div class="row">
          <div class="col-1">
            <button mat-button class="mat-usersbutton" (click)="pushAll()"><b>>></b></button>
          </div>
        </div>
        <div class="row">
          <p> </p>
        </div>
        <div class="row">
          <div class="col-1">
            <button mat-button class="mat-usersbutton" (click)="popSelected()"><b><</b></button>
          </div>
        </div>
        <div class="row">
          <div class="col-1">
            <button mat-button class="mat-usersbutton" (click)="popAll()"><b><<</b></button>
          </div>
        </div>
      </div>    
  </mat-grid-tile>

  <mat-grid-tile colspan="2">    
    <div class="div-grid-tile3">       
      <mat-selection-list [(ngModel)]="usersFinalSelected">
        <mat-list-option *ngFor="let su of selectedUsersData" [value]="su">
          {{su.fhir_identifier}} &nbsp;&nbsp; {{su.name}}
        </mat-list-option>
      </mat-selection-list>
    </div>    
  </mat-grid-tile>
</mat-grid-list>

<div mat-dialog-actions align="end">
  <button mat-button color="usersbutton" (click)="onNoClick()">Cancel</button>
  <button mat-button color="usersbutton" (click)="onCloseClick()" cdkFocusInitial>Ok</button>
</div>




