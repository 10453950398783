<div class="container geofencing">
  
  <!-- Header of page <mat-icon> satellite</mat-icon> -->
  <div class="row">
    <div class="col-6"> 
      <mat-label id="re" style="font-size: 18px;"><b>Geofencing - manage perimeters (u: {{preferredUsername}})</b></mat-label>  
      <mat-divider></mat-divider>    
    </div>
  </div>

  <div class="row row-cols-12">
    <div class="col-6">      
      <mat-form-field >
        <mat-label>Select a user</mat-label>
        <mat-select #toppings>                
          <mat-select-trigger>
            {{toppings.value}}
          </mat-select-trigger>       
          <table>           
          <mat-option *ngFor="let u of usersDataSource.data" [value]="u.name"              
             (onSelectionChange)="onUserChange(u, $event)">              
            <tr> 
              <td width="240px">{{u.fhir_identifier}}</td> 
              <td width="170px">{{u.name}}</td> 
              <td width="20px" *ngIf="u.perim_count!=0">{{u.perim_count}}</td> 
            </tr> 
          </mat-option>
        </table>                                
        </mat-select>
      </mat-form-field>
    </div>
   
    <div class="col-5">      
      <mat-form-field>
        <mat-label>Focus to perimeter</mat-label>
        <mat-select>
          <mat-option *ngFor="let p of perimetersDataSource.data" [value]="p.id"              
            (onSelectionChange)="onPerimeterChange(p, $event)">
            {{p.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-1">
      <div class="float-end">
        <button mat-icon-button> 
          <mat-icon [ngStyle]="{'color':'white'}" (click)="logOut()">logout</mat-icon>
        </button>
      </div>
  </div>    
  </div>

  <div class="row row-cols-12">
    <div class="col-2">      
      <button class="mat-flat-button mat-flat-button-d" color="primary" (click)="editPerimeter()"
      [hidden]="editPerimeterDisabled">{{perimeterSelected.selected?"Update perimeter":"New perimeter"}}</button>            
    </div>
    <div class="col-9">      
      <button class="mat-flat-button mat-flat-button-d" color="primary" (click)="deletePerimeter()"
      [hidden]="editPerimeterDisabled || !perimeterSelected.selected">Delete perimeter</button>            
    </div>    
   <!--  <div class="col-7">      
      <button class="mat-flat-button mat-flat-button-d" color="primary" (click)="deletePerimeterForAll()"
      [hidden]="editPerimeterDisabled || !perimeterSelected.selected">Delete for all users</button>            
    </div>     -->
    <div class="col-1">      
      <button mat-icon-button (click)="openDataDisplayDialog()"
      [hidden]="editPerimeterDisabled || !perimeterSelected.selected"><mat-icon [ngStyle]="{'color':'white'}" >notes</mat-icon></button>            
    </div>
  </div>

  <mat-expansion-panel (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false"
                        [expanded]= "true"
                        [hidden]= "panelHidden">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>{{perimeterSelected.selected?"Update perimeter":"New perimeter"}}</b>        
      </mat-panel-title>
      <!-- <mat-panel-description>
        Currently I am {{panelOpenState ? 'open' : 'closed'}}
      </mat-panel-description> -->
    </mat-expansion-panel-header>
    <form [formGroup]="registerPerimeter" (ngSubmit)="savePerimeter()">
      <div class="row row-cols-12">
        <div class="col-6">       
            <mat-form-field class="form-field">
              <mat-label>Perimeter name</mat-label>
              <input matInput formControlName="perimeter_name" name="perimeter_name" [(ngModel)]="selectedPerimeter.name"
              value="Test">
              <mat-error *ngIf="registerPerimeter.value.perimeter_name">You must provide a non empty name</mat-error>
            </mat-form-field>        
        </div>
      </div>
<!--       <div class="row row-cols-12">
        <div class="col-4">        
            <mat-form-field class="form-field">
              <mat-label>Location</mat-label>
              <input matInput formControlName="center_name" name="center_name" [(ngModel)]="selectedPerimeter.center.name"
              value="Lugano">
              <mat-error *ngIf="registerPerimeter.value.center_name">You must provide a non empty name</mat-error>                            
            </mat-form-field>        
        </div>
        <div class="col-4">        
            <mat-form-field class="form-field">
              <mat-label>Longitude</mat-label>
              <input matInput formControlName="center_longitude" name="center_longitude" [(ngModel)]="selectedPerimeter.center.coordinates[0]"
              value="8.0">
              <mat-error *ngIf="registerPerimeter.value.center_longitude">Longitude must be a number</mat-error>
            </mat-form-field>        
        </div>
        <div class="col-4">        
            <mat-form-field class="form-field">
              <mat-label>Latitude</mat-label>
              <input matInput formControlName="center_latitude" name="center_latitude" [(ngModel)]="selectedPerimeter.center.coordinates[1]"            
              value="46.0">
              <mat-error *ngIf="registerPerimeter.value.center_latitude">Latitude must be a number</mat-error>
            </mat-form-field>        
        </div>
      </div> -->
      <div class="row row-cols-12">
        <div class="col-4">        
            <mat-form-field>
              <mat-label>Initial map zoom</mat-label>
              <input matInput formControlName="init_zoom" name="init_zoom" [(ngModel)]="selectedPerimeter.init_zoom"
              value="11">            
              <mat-error *ngIf="registerPerimeter.get('init_zoom')">Zoom level must be a positive integer</mat-error>  
            </mat-form-field>                    
        </div>
        <div class="col-4">        
            <mat-form-field>
              <mat-label>Message IN</mat-label>
              <input matInput formControlName="message_IN" name="message_IN" [(ngModel)]="selectedPerimeter.msg_inside"
              value="Entering">                            
            </mat-form-field>        
        </div>
        <div class="col-4">        
            <mat-form-field>
              <mat-label>Message OUT</mat-label>
              <input matInput formControlName="message_OUT" name="message_OUT" [(ngModel)]="selectedPerimeter.msg_outside"
              value="Exiting">                            
            </mat-form-field>        
        </div>
      </div>
      <div class="row row-cols-12">
        <div class="col-8">
          <mat-form-field>
            <mat-label>Enter period of validity</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <!--input matStartDate formControlName="start" placeholder="Start date" (dateChange)="startDatePicker.next($event)"-->
              <input matStartDate formControlName="start" placeholder="Start date" [(ngModel)]="selectedPerimeter.start_timestamp">
              <input matEndDate formControlName="end" placeholder="End date" [(ngModel)]="selectedPerimeter.value_timestamp">
            </mat-date-range-input>                    

            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          <!-- 
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
            <mat-hint>{{selectedPerimeter.start_timestamp}} <br/> {{selectedPerimeter.value_timestamp}}</mat-hint>
          </mat-form-field>
        </div>
      </div>
    </form>
   
    <div class="row row-cols-12">            
        <div class="col-8"></div>
        <div class="col-2">
          <button mat-flat-button color="primary" [hidden]="cancelPerimeterDisabled" (click)="closePerimeterEditor()" cdkFocusInitial>Cancel</button>    
        </div>
        <div class="col-2">
          <button mat-flat-button color="primary" type="submit" [disabled]="!registerPerimeter.valid" [hidden]="savePerimeterDisabled" (click)="savePerimeter()">Save</button>          
        </div>
        <!-- <div class="col-2">
          <button mat-flat-button color="primary" [hidden]="extendPerimeterToAllDisabled" (click)="openUsersDialog()" >Save to other users</button>           
        </div> -->
    </div>
    
  </mat-expansion-panel>

  <div class="row row-cols-12">    
      <div id="map-container" class="map-container" tabindex="0"></div>
      <div style="display: none;">        
        <!-- Tooltip -->
        <div id="tooltip"></div>
      </div>
      <div id="mouse-position" class="mouse-position"></div>   
  </div>
  
</div>


